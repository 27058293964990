.App {
  font-family: "Roboto, Helvetica Neue";
  letter-spacing: 0.1rem;
  text-align: center;
  color: #795548;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.modalColor {
  background-color: #795548;
  border-color: #795548;
}
.modalColor:hover {
  background-color: #6d4c40;
  border-color: #6d4c40;
}
.modalTitle {
  color: #795548;
  font-size: xx-large;
}
.btn-about {
  transform: rotate(-90deg);
  position: fixed;
  right: 0;
  bottom: 50%;
  z-index: 1000;
  color: #795548;
  border-radius: 10px 10px 0px 0px;
  padding: 0.75em 1em 1em;
  border: #795548;
  letter-spacing: 1px;
  box-shadow: rgb(0 0 0 / 50%) 0px 0px 5px 0px;
  font-size: 0.85rem;
  text-transform: uppercase;
  font-weight: 600;
  transform-origin: right bottom;
}
