#navbar a {
  color: #795548;
}

#navbar-brand {
  color: #795548;
  letter-spacing: 1.8px;
  font-size: 1.6rem;
}

.btn-navbar {
  border: #795548 1px solid;
}

.navbar-badge {
  color: #795548;
  font-size: 1rem;
}

.cart-icon,
.logout-icon,
.login-icon {
  color: #795548;
}

#navbar-cart {
  margin-right: 5px;
}

.btn .badge {
  top: 1px;
}

#navbar-cart:hover,
#navbar-logout:hover,
#navbar-login:hover {
  background-color: #795548;
  opacity: 0.6;
}

#navbar-cart:hover > .cart-icon,
#navbar-logout:hover > .logout-icon,
#navbar-login:hover > .logout-icon {
  color: aliceblue;
}

#navbar-cart:hover > .navbar-badge,
#navbar-logout:hover > .navbar-badge,
#navbar-login:hover > .navbar-badge {
  color: aliceblue;
}

.dropdown-menu {
  /* background-color: #ded589; */
}

.navbar-dd-span {
  min-width: 75px;
}
