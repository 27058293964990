.cascading-right {
  margin-right: -50px;
}

#register-reset li a:hover {
  color: #795548;
  opacity: 0.6;
}

.btn-login {
  background-color: #795548;
  border: #795548 1px solid;
  color: white;
}

.btn-login:hover {
  background-color: #795548;
  opacity: 0.6;
  color: white;
}

@media (max-width: 991.98px) {
  .cascading-right {
    margin-right: 0;
  }
}

.custom-login-image{
  object-fit: cover;
  height: 90vh;
}